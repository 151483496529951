import React, {useContext} from "react";
import './MenuLeft.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Hidden from '@material-ui/core/Hidden';
import {
  Link,
  useLocation,
} from "react-router-dom";
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import SlugLink from 'components/Router/SlugLink';
import Utils from 'utils/Utils';

function MenuLeft() {
  const { data } = useQuery(Queries.rubriques);
  let rubriques=data ? data.rubriques : [];
  let pages=data ? data.pages : [];
  const { t, translate, currentRub } = useContext(SettingsContext);
  const tab=[
    {
        titre: t('expositions'),
        type: 'expositions',
        link: true,
        id:0,
    },
    {
        titre: t('rendez-vous'),
        type: 'rdvs',
        link: true,
        id:0,
    },
    {
        titre: t('education-artistique'),
        type: 'education-artistique',
        link: true,
        id:0,
    },
    {
        titre: t('residences'),
        type: 'residences',
        link: true,
        id:0,
    },
    {
        titre: t('editions'),
        type: 'editions',
        link: true,
        id:0,
    },
    ...rubriques.map((r)=>{return {
        titre:translate(r,'title'),
        type:r.slugs[0].slug,
        link:false,
        pages: r.pages,
        id:r.id,
    }}),
    ...pages.map((p)=>{
      //console.log(p);
      return {
        titre:translate(p,'title'),
        type:'pages/'+p.slugs[0].slug,
        link:true,
        id:p.id,
    }})
  ];

  let classes='menu-left';
  const {pathname}=useLocation();
  return <Hidden xsDown>
      <div className={classes}>
          {tab.map((item,i)=>{
            let itemClasses='menu-left-item';
            if (pathname.startsWith(`/${item.type}`)) itemClasses+=' active';
            if (currentRub===item.id) itemClasses+=' active';
            if (item.link===true) return <Link key={item.type} to={"/"+item.type}><div className={itemClasses}>{item.titre}</div></Link>;
            return <div key={item.type} className={itemClasses}>
              {item.titre}
              {item.pages.length>0 && <div className="menu-left-item-pages">
                {item.pages.map((page,i)=>{
                  let pageClasses='menu-left-item';
                  if (Utils.pathPageMatch(pathname,page)) pageClasses+=' active';
                  return <SlugLink key={page.id} item={page}><div className={pageClasses}>{page.title}</div></SlugLink>;
                })}
              </div>}
            </div>;
          })}
      </div>
    </Hidden>;
}

export default MenuLeft;
