import React, {useContext} from "react";
import './Expo.scss';
import Utils from 'utils/Utils';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  useHistory,
} from "react-router-dom";
import shim from 'string.prototype.matchall/shim';
shim();

function Expo({expo,toggle=true}) {
  const { t, filter, setFilter, translate } = useContext(SettingsContext);
  const { search, ...otherFilter } = filter;
  let history = useHistory();
  //expo
  const expoRegex=/\+([^\s]+)/g;
  const expoRes=Utils.accentsTidyLw(search).matchAll(expoRegex) || [];
  const expos=[...expoRes].map((t)=>t[1]);
  const active=expos.reduce((acc,t)=>{
      return acc || Utils.accentsTidyLw(t.replace(/\s/g,'_'))===Utils.accentsTidyLw(expo.title.replace(/\s/g,'_'));
  },false);
  const toggleExpo=()=>{
      const escapedExpo=Utils.accentsTidy(expo.title.replace(/\s/g,'_'));
      if (!active) {
          setFilter({search:(search+" +"+escapedExpo).trim(), ...otherFilter});
      } else {
          if (toggle) setFilter({search:search.replace("+"+escapedExpo,'').trim()});
      }
      if (window.location.pathname!=='/') {
        history.push("/");
      }
  };
  let classes="expo";
  if (active) classes+=" active";
  const Picto=Utils.pictos.groupe;
  return <div className="menu-left-item-expo">
      <div className="item-type">{t('expositions-en-ligne')}</div>
      <div className={classes}>
        <span className='expo-span' onClick={toggleExpo}>{translate(expo,'title')}</span>
        <div className="picto carre" onClick={toggleExpo}><Picto/></div>
        <div className="infos"><a href="https://www.centredartlelait.com/#expos&id_article=899" target="_blank" rel="noreferrer">+</a></div>
      </div>
  </div>
  ;
}

export default Expo;
