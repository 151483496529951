import React, {useContext} from "react";
import './Artiste.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import {
  useHistory,
} from "react-router-dom";
import shim from 'string.prototype.matchall/shim';
shim();

function Artiste({artiste, active=false}) {
  const { filter, setFilter } = useContext(SettingsContext);
  const { search, ...otherFilter } = filter;
  let history = useHistory();
  const artisteRegex=/@([^\s-_]+)/g;
  const addArtiste=()=>{
      const artistesRes=Utils.accentsTidy(search).matchAll(artisteRegex) || [];
      const artistes=[...artistesRes].map((t)=>t[1]);
      if (artistes.indexOf(artiste.name)===-1) {
          if (window.location.pathname!=='/') {
            history.push("/");
          }
          const escapedArtiste=Utils.accentsTidy(artiste.name.replace(/\s/g,'_'));
          setFilter({search:"@"+escapedArtiste, ...otherFilter});
      }
  };
  let classes='artiste';
  if (active) classes+=' active';
  return <span className={classes} onClick={active ? addArtiste : undefined}>{artiste.name}</span>;
}

export default Artiste;
