import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ExpoEnCours({expo,home=false}) {
  const dataExpo=[
    {key:'expo-en-cours',value:expo.title},
    Utils.renderArtistes(expo,false,false),
    {key:null,value:Utils.dates(expo)},
    {animation:expo.animation},
  ];
  const dataExpoHome=[
    {key:'expo-en-cours',value:expo.title},
    {animation:expo.animation},
    Utils.renderArtistes(expo),
    {key:'date',value:Utils.dates(expo)},
  ];
  return <div className="expo-bloc">
    <SlugLink item={expo}><CartoucheInfos data={home ? dataExpoHome : dataExpo}/></SlugLink>
  </div>;
}
export default ExpoEnCours;
