import React, {useContext} from "react";
import './Tag.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import {
  useHistory,
} from "react-router-dom";
import shim from 'string.prototype.matchall/shim';
shim();

function Tag({tag}) {
  const { translate, filter, setFilter } = useContext(SettingsContext);
  const { search, ...otherFilter } = filter;
  let history = useHistory();
  const tagRegex=/#([^\s-]+)/g;
  const addTag=()=>{
      const tagsRes=Utils.accentsTidy(search).matchAll(tagRegex) || [];
      const tags=[...tagsRes].map((t)=>t[1]);
      if (tags.indexOf(tag.tag)===-1) {
          if (window.location.pathname!=='/') {
            history.push("/");
          }
          const escapedTag=Utils.accentsTidy(tag.tag.replace(/\s/g,'-'));
          setFilter({search:"#"+escapedTag, ...otherFilter});
      }
  };
  return <span className='tag' onClick={addTag}>{translate(tag,'tag')}</span>;
}

export default Tag;
