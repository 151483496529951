import React, { useState, useContext, useRef, useEffect } from "react";
import './Rechercher.scss';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import CloseIcon from '@material-ui/icons/Close';
import { SettingsContext } from 'Providers/SettingsProvider';
import {
  useHistory,
} from "react-router-dom";

function Rechercher({ className='', xs }) {
  const { t, filter, setFilter } = useContext(SettingsContext);
  let classes=className+" rechercher";
  const { search, ...otherFilter } = filter;
  const [ open, setOpen ] = useState(false);
  const ref=useRef();
  let history = useHistory();
  const handleChange = (e)=>{
      ref.current.focus();
      if (window.location.pathname!=='/') {
          history.push("/");
      }
      setFilter({search:e.target.value, ...otherFilter});
  };
  const handleOpen = ()=>{
      if (open) setOpen(false);
      else {
        ref.current.focus();
        setOpen(true);
      }
  };
  useEffect(()=>{
    const myRef=ref.current;
    const handleBlur = ()=>{
        if (search==='') setOpen(false);
    };
    myRef.addEventListener('blur',handleBlur);
    return ()=> myRef.removeEventListener('blur',handleBlur)
  },[search]);
  if (open) classes+=" open";
  if (xs) classes+=" xs";
  return <div className={classes}>
      <Grid container alignContent="flex-end">
          <Grid item style={{width:30}}>
              <IconButton aria-label="search" onClick={()=>search!=='' ? handleChange({target:{value:''}}) : handleOpen()}>
                {search==='' && <SearchIcon/>}
                {search!=='' && <CloseIcon/>}
              </IconButton>
          </Grid>
          <Grid item xs>
              <TextField inputRef={ref} id="standard-basic" label={!xs && t('rechercher')} onChange={handleChange} value={search} fullWidth/>
          </Grid>
      </Grid>
  </div>;
}

export default Rechercher;
