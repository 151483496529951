import React from "react";
import './Residences.scss'
import ResidenceEnCours from 'components/Residence/ResidenceEnCours';
import ResidencePassee from 'components/Residence/ResidencePassee';
import ResidenceAvenir from 'components/Residence/ResidenceAvenir';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { DateTime } from "luxon";

function Residences() {
  const { data } = useQuery(Queries.residences);
  let residencesEnCours= data ? data.residences.filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now() && DateTime.fromISO(o.date_from)<=DateTime.now()) : [];
  let residencesPassees= data ? data.residences.filter((o)=>DateTime.fromISO(o.date_to)<DateTime.now()) : [];
  let residencesAvenir= data ? data.residences.filter((o)=>DateTime.fromISO(o.date_from)>DateTime.now()) : [];
  return (<div className="residences">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {residencesEnCours.map((residence,i)=><ResidenceEnCours key={residence.id} residence={residence}/>)}
        {residencesPassees.map((residence,i)=><ResidencePassee key={residence.id} residence={residence}/>)}
      </Grid>
      <Grid item xs={12} md={4}>
        {residencesAvenir.map((residence,i)=><ResidenceAvenir key={residence.id} residence={residence}/>)}
      </Grid>
    </Grid>
  </div>);
}
export default Residences;
