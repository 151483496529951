import React from 'react';
import { useQuery } from '@apollo/client';
import {
  Route,
  Switch,
} from "react-router-dom";
import Exposition from 'components/Exposition';
import Page from 'components/Page';
import Rdv from 'components/Rdv/Rdv';
import Residence from 'components/Residence/Residence';
import Edition from 'components/Edition/Edition';
import AcProject from 'components/Ac/AcProject';
import AcInstance from 'components/Ac/AcInstance';
import Queries from 'utils/Queries';

function AllRoutes ({
  localRoutes=[],
}) {
  const { data } = useQuery(Queries.allroutes);
  const expositions = data ? data.expositions : [];
  const pages = data ? data.pages : [];
  const rdvs = data ? data.rdvs : [];
  const residences = data ? data.residences : [];
  const editions = data ? data.editions : [];
  const ac_projects = data ? data.ac_project : [];
  const ac_instances = data ? data.ac_instances : [];
  let res=[];
  expositions.forEach((expo, i) => {
      res.push(<Route key={`/expositions/${expo.id}`} path={`/expositions/${expo.id}`}>
          <Exposition id={expo.id}/>
      </Route>);
      if (expo.slugs && expo.slugs.length>0) {
          expo.slugs.forEach((slug, i) => {
              res.push(<Route key={`/expositions/${slug.slug}`} path={`/expositions/${slug.slug}`}>
                  <Exposition id={expo.id}/>
              </Route>);
          });
      }
  });
  pages.forEach((page, i) => {
      res.push(<Route key={`/pages/${page.id}`} path={`/pages/${page.id}`}>
          <Page id={page.id}/>
      </Route>);
      if (page.slugs && page.slugs.length>0) {
          page.slugs.forEach((slug, i) => {
              res.push(<Route key={`/pages/${slug.slug}`} path={`/pages/${slug.slug}`}>
                  <Page id={page.id}/>
              </Route>);
          });
      }
  });
  rdvs.forEach((rdv, i) => {
      res.push(<Route key={`/rdvs/${rdv.id}`} path={`/rdvs/${rdv.id}`}>
          <Rdv id={rdv.id}/>
      </Route>);
      if (rdv.slugs && rdv.slugs.length>0) {
          rdv.slugs.forEach((slug, i) => {
              res.push(<Route key={`/rdvs/${slug.slug}`} path={`/rdvs/${slug.slug}`}>
                  <Rdv id={rdv.id}/>
              </Route>);
          });
      }
  });
  residences.forEach((residence, i) => {
      res.push(<Route key={`/residences/${residence.id}`} path={`/residences/${residence.id}`}>
          <Residence id={residence.id}/>
      </Route>);
      if (residence.slugs && residence.slugs.length>0) {
          residence.slugs.forEach((slug, i) => {
              res.push(<Route key={`/residences/${slug.slug}`} path={`/residences/${slug.slug}`}>
                  <Residence id={residence.id}/>
              </Route>);
          });
      }
  });
  editions.forEach((edition, i) => {
      res.push(<Route key={`/editions/${edition.id}`} path={`/editions/${edition.id}`}>
          <Edition id={edition.id}/>
      </Route>);
      if (edition.slugs && edition.slugs.length>0) {
          edition.slugs.forEach((slug, i) => {
              res.push(<Route key={`/editions/${slug.slug}`} path={`/editions/${slug.slug}`}>
                  <Edition id={edition.id}/>
              </Route>);
          });
      }
  });
  ac_projects.forEach((ac_project, i) => {
      res.push(<Route key={`/ac_project/${ac_project.id}`} path={`/ac_project/${ac_project.id}`}>
          <AcProject id={ac_project.id}/>
      </Route>);
      if (ac_project.slugs && ac_project.slugs.length>0) {
          ac_project.slugs.forEach((slug, i) => {
              res.push(<Route key={`/ac_project/${slug.slug}`} path={`/ac_project/${slug.slug}`}>
                  <AcProject id={ac_project.id}/>
              </Route>);
          });
      }
  });
  ac_instances.forEach((ac_instance, i) => {
      res.push(<Route key={`/ac_instances/${ac_instance.id}`} path={`/ac_instances/${ac_instance.id}`}>
          <AcInstance id={ac_instance.id}/>
      </Route>);
      if (ac_instance.slugs && ac_instance.slugs.length>0) {
          ac_instance.slugs.forEach((slug, i) => {
              res.push(<Route key={`/ac_instances/${slug.slug}`} path={`/ac_instances/${slug.slug}`}>
                  <AcInstance id={ac_instance.id}/>
              </Route>);
          });
      }
  });
  res=[...res, ...localRoutes];
  return <Switch>
      {res}
  </Switch>;
};

export default AllRoutes;
