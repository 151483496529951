import React, {useContext} from "react";
import './Biographie.scss';
import { SettingsContext } from 'Providers/SettingsProvider';


function Biographie({artiste}) {
  const { t, translate } = useContext(SettingsContext);
  return <div className="biographie">
  <div className="biographie-titre">{t('biographie')}</div>
  <div className="biographie-nom">{artiste.name}</div>
  <div className="biographie-text"  dangerouslySetInnerHTML={{__html:translate(artiste,'bio')}}/>
  </div>
}

export default Biographie;
