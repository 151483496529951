import React, {useContext} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import Conf from 'utils/Conf';
import RdvTile from 'components/Rdv/RdvTile';

function AcInstance({id}) {
  const { translate } = useContext(SettingsContext);
  const { data } = useQuery(Queries.ac_instance,{
    variables: { id:parseFloat(id) },
  });
  console.log(data);
  const ac_instance=data ? data.ac_instances[0] : null;
  return ac_instance ? <div className="ac-instance">
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <div className="ac-instance-visuel">
            <img key={ac_instance.visuel.id} alt="" src={`${Conf.apiUrl}/assets/${ac_instance.visuel.id}?key=carre-small`}/>
        </div>
      </Grid>
      <Grid item xs={12} sm={4}>
        <div className="ac-instance-type">
          {ac_instance.ac_projects && ac_instance.ac_projects.map((acProject,i)=>{
            return <React.Fragment key={acProject.ac_project_id.id}>{i>0 && ', '}<span>{translate(acProject.ac_project_id,'title')}</span></React.Fragment>})
          }
        </div>
        <div className="ac-instance-titre">{ac_instance.title}</div>
        <div className="ac-instance-texte" dangerouslySetInnerHTML={{__html:ac_instance.text}}></div>
      </Grid>
      <Grid item xs={12} md={4}>
        {ac_instance.rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv.rdvs_id} size="mini"/>)}
      </Grid>
    </Grid>

  </div> : '';
}
export default AcInstance;
