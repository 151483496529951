import React, { useState, createContext, useMemo, useEffect } from "react";
import { useQuery } from '@apollo/client';
import Utils from 'utils/Utils';
import Queries from 'utils/Queries';

export const SettingsContext = createContext({});

const SettingsProvider = ({children})=>{
    const [ filter, setFilter ] = useState({search:'', types:[], expos:[], archives:false});
    const [ lang, setLang ] = useState('fr');
    const [ tick, setTick ] = useState(0);
    const [ scrollY, setScrollY ] = useState(0);
    const [ currentRub, setCurrentRub ] = useState(null);
    const { data } = useQuery(Queries.settings);
    const trads=data ? data.interface.reduce((acc,item,i)=>{
        const key=item.key;
        const value=item.value;
        const text=item.text;
        const translations=item.translations.reduce((accTr,tr,j)=>{
            const lang=tr.languages_code.code;
            const valueTr=tr.value;
            const textTr=tr.text;
            return {[lang]:{value:valueTr,text:textTr},...accTr};
        },{});
        return {[key]:{value,text,translations},...acc};
    },{}) : {};
    const settings=useMemo(() => data ? data.settings : {}, [data]);
    useEffect(()=>{
        const root = document.documentElement;
        root.style.setProperty('--hover-color', settings.contrast_color || "#299DD8");
    },[settings]);
    const handleScroll=()=>{
        setScrollY(window.scrollY);
    };
    useEffect(()=>{
        document.addEventListener('scroll',handleScroll);
        return ()=>document.removeEventListener('scroll',handleScroll);
    },[]);
    useEffect(()=>{
      window.scrollTo(0, 84);
    },[filter]);
    const t=(key,type='value')=>{
        const tr=trads[key] ? ( trads[key].translations[lang] || trads[key] ) : {value:null, text:null};
        return  tr[type] || '';
    };
    const translate=(item,key)=>{
        return Utils.translate(item,lang,key);
    };
    const value={settings, trads, lang, setLang, t, filter, setFilter, scrollY, translate, tick, setTick, currentRub, setCurrentRub};
    return (
        <SettingsContext.Provider value={value}>
            {data && children}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
