import React, {useContext} from "react";
import { useQuery } from '@apollo/client';
import './Rdv.scss'
import Conf from 'utils/Conf';
import { DateTime } from "luxon";
import _ from 'lodash'
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import Grid from '@material-ui/core/Grid';

export const datesRdv=(rdv)=>[
  {titre:'',date:rdv.date,time_from:rdv.time_from,time_to:rdv.time_to},
  ...rdv.repetitions
];
export const parseTime=(evt,short=false)=>{
  const { time_from, time_to }=evt;
  if ( time_from && time_to ) {
    const tf=DateTime.fromISO(time_from);
    const tt=DateTime.fromISO(time_to);
    if (short) {
      const ftf=tf.toFormat("H:mm");
      const ftt=tt.toFormat("H:mm");
      return `${ftf}-${ftt}`;
    } else {
      const ftf=tf.toFormat("m")==="0" ? tf.toFormat("H'h'") : tf.toFormat("H'h'mm");
      const ftt=tt.toFormat("m")==="0" ? tt.toFormat("H'h'") : tt.toFormat("H'h'mm");
      return `de ${ftf} à ${ftt}`;
    }
  };
  if ( time_from ) {
    const tf=DateTime.fromISO(time_from);
    if (short) {
      const ftf=tf.toFormat("H:mm");
      return ftf;
    } else {
      const ftf=tf.toFormat("m")==="0" ? tf.toFormat("H'h'") : tf.toFormat("H'h'mm");
      return `à ${ftf}`;
    }
  };
  return '';
};
export const parseYear=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("y")===DateTime.now().toFormat("y") ? "" : df.toFormat("y");
  return fdf;
};
export const parseMonth=(evt,lang)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.setLocale(lang).toFormat("LLLL");
  return fdf;
};
export const parseDay=(evt)=>{
  const { date }=evt;
  const df=DateTime.fromISO(date);
  const fdf=df.toFormat("d");
  return fdf;
};
export const parseDates=(rdv,lang)=>{
  const dates=[...datesRdv(rdv)].sort((a,b)=>a.date-b.date);
  let res=_.groupBy(dates,(o)=>o.titre || '');
  Object.keys(res).forEach((titre) => {
      res[titre]=_.groupBy(res[titre],(evt)=>parseTime(evt));
      Object.keys(res[titre]).forEach((horaire)=>{
          res[titre][horaire]=_.groupBy(res[titre][horaire],(evt)=>parseYear(evt));
          Object.keys(res[titre][horaire]).forEach((year)=>{
              res[titre][horaire][year]=_.groupBy(res[titre][horaire][year],(evt)=>parseMonth(evt,lang));
              Object.keys(res[titre][horaire][year]).forEach((month)=>{
                    res[titre][horaire][year][month].forEach((item, i) => {
                        res[titre][horaire][year][month][i]=parseDay(item);
                    });
              });
          });
      });
  });
  return res;
};
export const displayDates=(dates,t)=>{
  return <>
  {Object.keys(dates).map((titre,it) => <React.Fragment key={it}>
      {titre && <div className="rdv-evt-titre">{titre} :</div>}
      <div className="rdv-evt-horaires">
      {Object.keys(dates[titre]).sort().map((horaire,ih)=><React.Fragment key={ih}>
          {ih>0 && <br />}
          {Object.keys(dates[titre][horaire]).sort((a,b)=>parseInt(a || 0)-parseInt(b || 0)).map((year,iy)=><React.Fragment key={iy}>
              {iy>0 && ', '}
              {Object.keys(dates[titre][horaire][year]).sort().map((month,im)=><React.Fragment key={im}>
                    {im>0 && ', '}
                    {dates[titre][horaire][year][month].sort((a,b)=>parseInt(a)-parseInt(b)).map((day, id) =>
                        <React.Fragment key={id}>{id>0 && (id===dates[titre][horaire][year][month].length-1 ? ` ${t('et')} `:', ')}{day}</React.Fragment>
                    )}
                    {month && ' '+month}
              </React.Fragment>)}
              {year && ' '+year}
          </React.Fragment>)}
          {horaire && ' '+horaire}
      </React.Fragment>)}
      </div>
  </React.Fragment>)}
  </>;
};

function Rdv({id}) {
  const { t, lang, translate } = useContext(SettingsContext);
  const { data } = useQuery(Queries.rdv,{
    variables: { id:parseFloat(id) },
  });
  let rdv=data ? data.rdvs[0] : null;
  return rdv ? <div className="rdv">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <div className="rdv-visuel">
              <img key={rdv.visuel.id} alt="" src={`${Conf.apiUrl}/assets/${rdv.visuel.id}?key=carre-small`}/>
          </div>
        </Grid>
        <Grid item xs={12} sm={4}>
          <div className="rdv-type">{translate(rdv.type,'name')}</div>
          <div className="rdv-titre">{rdv.title}</div>
          <div className="rdv-dates">
              {displayDates(parseDates(rdv,lang),t)}
          </div>
          <div className="rdv-lieu">{rdv.lieu}</div>
          <div className="rdv-ville">{rdv.ville}</div>
          <div className="rdv-texte" dangerouslySetInnerHTML={{__html:rdv.text}}></div>
        </Grid>
      </Grid>
  </div> : '';
}
export default Rdv;
