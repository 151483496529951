import React, {useContext} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import CartoucheInfos from 'components/CartoucheInfos';
import AcInstanceTile from './AcInstanceTile';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';

function Ac() {
  const { translate } = useContext(SettingsContext);
  const { data } = useQuery(Queries.ac);
  const ac=data ? data.ac : null;
  const acProjects=data ? data.ac_project : null;
  const acInstances=data ? data.ac_instances : null;
  const dataAc=ac ? [
    {key:null,value:translate(ac,'title')},
  ]: [];
  return ac ? <div className="ac">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CartoucheInfos data={dataAc}/>
        <div className="ac-text" dangerouslySetInnerHTML={{__html:ac ? translate(ac,'text') : ''}}/>
        {acProjects && <div className="liste">
          {acProjects.map((acProject)=><div className="list-item" key={acProject.id}>
            - <SlugLink key={acProject.id} item={acProject}>{translate(acProject,'title_liste')}</SlugLink>
          </div>)}
        </div>}
        <div className="ac-text" dangerouslySetInnerHTML={{__html:ac ? translate(ac,'text2') : ''}}/>
        <hr />
        {acInstances && <Grid container spacing={2}>
          {acInstances.map((acInstance)=><Grid key={acInstance.id} item xs={12} md={6}>
            <AcInstanceTile acInstance={acInstance}/>
          </Grid>)}
        </Grid>}
      </Grid>
    </Grid>
  </div> : '';
}
export default Ac;
