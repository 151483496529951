import React, {useContext} from "react";
import './Ac.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';

function AcInstanceTile({acInstance}) {
  const { translate } = useContext(SettingsContext);
  return acInstance ? <SlugLink item={acInstance}>
    <div className="ac-instance-tile">
        <div className="ac-instance-tile-type">
          {acInstance.ac_projects && acInstance.ac_projects.map((acProject,i)=>{
            return <React.Fragment key={acProject.ac_project_id.id}>{i>0 && ', '}<span>{translate(acProject.ac_project_id,'title')}</span></React.Fragment>})
          }
        </div>
        <div className="ac-instance-tile-titre">{acInstance.title}</div>
        {acInstance.visuel && <div className="ac-instance-tile-visuel">
            <img key={acInstance.visuel.id} alt="" src={`${Conf.apiUrl}/assets/${acInstance.visuel.id}?key=carre-small`}/>
        </div>}
        <div className="ac-instance-tile-resume" dangerouslySetInnerHTML={{__html:acInstance.resume}}></div>
    </div>
  </SlugLink> : '';
}
export default AcInstanceTile;
