import React from "react";
import './Expositions.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import ExpoPassee from 'components/Exposition/ExpoPassee';
import ExpoAvenir from 'components/Exposition/ExpoAvenir';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import { DateTime } from "luxon";

function Expositions() {
  const { data } = useQuery(Queries.expositions);
  let exposEnCours= data ? data.expositions.filter((o)=>DateTime.fromISO(o.date_to)>=DateTime.now() && DateTime.fromISO(o.date_from)<=DateTime.now()) : [];
  let exposPassees= data ? data.expositions.filter((o)=>DateTime.fromISO(o.date_to)<DateTime.now()) : [];
  let exposAvenir= data ? data.expositions.filter((o)=>DateTime.fromISO(o.date_from)>DateTime.now()) : [];
  return (<div className="expositions">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {exposEnCours.map((expo,i)=><ExpoEnCours key={expo.id} expo={expo}/>)}
        {exposPassees.map((expo,i)=><ExpoPassee key={expo.id} expo={expo}/>)}
      </Grid>
      <Grid item xs={12} md={4}>
        {exposAvenir.map((expo,i)=><ExpoAvenir key={expo.id} expo={expo}/>)}
      </Grid>
    </Grid>
  </div>);
}
export default Expositions;
