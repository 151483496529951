import './App.scss';
import {
  BrowserRouter as Router,
  Route,
} from "react-router-dom";
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Header from 'components/Header/Header';
import TopBar from 'components/TopBar/TopBar';
import Accueil from 'components/Accueil/Accueil';
import Rdvs from 'components/Rdv/Rdvs';
import Residences from 'components/Residences';
import Editions from 'components/Editions';
import Ac from 'components/Ac';
import Expositions from 'components/Expositions';
import AllRoutes from 'components/Router/AllRoutes';
import ScrollToTop from 'components/Router/ScrollToTop';
import Footer from 'components/Footer';
import SettingsProvider from "Providers/SettingsProvider";
import MenuLeft from 'components/MenuLeft';
import Grid from '@material-ui/core/Grid';

const theme = createMuiTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#000',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#888',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  overrides: {
    // Style sheet name ⚛️
    MuiTabs: {
      // Name of the rule
      indicator: {
        // Some CSS
        height: 8,
      },
    },
  },
});

function App() {
  const localRoutes=[
    <Route key='main' exact path="/">
      <Accueil/>
    </Route>,
    <Route key='expositions' exact path="/expositions">
      <Expositions/>
    </Route>,
    <Route key='rdvs' exact path="/rdvs">
      <Rdvs/>
    </Route>,
    <Route key='editions' exact path="/editions">
      <Editions/>
    </Route>,
    <Route key='ac' exact path="/education-artistique">
      <Ac/>
    </Route>,
    <Route key='residences' exact path="/residences">
      <Residences/>
    </Route>,
  ];
  return (
    <ThemeProvider theme={theme}>
    <SettingsProvider>
    <Router>
      <Header/>
      <TopBar/>
      <div className="body-container">
      <Grid container spacing={2}>
          <Grid item xs={12} md={3}>
              <MenuLeft/>
          </Grid>
          <Grid item xs={12} md={9}>
              <AllRoutes localRoutes={localRoutes}/>
          </Grid>
      </Grid>
      </div>
      <Footer/>
      <ScrollToTop/>
    </Router>
    </SettingsProvider>
    </ThemeProvider>
  );
}

export default App;
