import React, {useContext} from "react";
import { useQuery } from '@apollo/client';
import './Exposition.scss'
import Queries from 'utils/Queries';
import Biographie from 'components/Artiste/Biographie';
import Ressources from 'components/Ressources';
import CartoucheInfos from 'components/CartoucheInfos';
import Utils from 'utils/Utils';
import Grid from '@material-ui/core/Grid';
import RdvTile from 'components/Rdv/RdvTile';
import Animation from 'components/Animation';
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';

import SwiperCore, { Navigation, Autoplay, A11y } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
SwiperCore.use([Navigation, Autoplay, A11y]);

function Exposition({id}) {
  const { translate } = useContext(SettingsContext);
  const { data } = useQuery(Queries.exposition,{
    variables: { id:parseFloat(id) },
  });
  const expo=data ? data.expositions[0] : null;
  const dataExpo=expo ? [
    {key:'exposition',value:translate(expo,'title')},
    Utils.renderArtistes(expo,true,false),
    {key:null,value:Utils.dates(expo)},
  ]: [];
  return expo ? <div className="exposition">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <CartoucheInfos data={dataExpo}/>
          <div className="exposition-diaporama">
            {
            expo.diaporama && expo.diaporama.length>0 ?
              <Swiper
              speed={800}
              loop
              autoplay={{
                delay: 3000,
              }}
              >
                {expo.diaporama.map((diap,i)=>{
                    const img=diap.directus_files_id;
                    return <SwiperSlide key={img.id}><img alt={img.title} src={`${Conf.apiUrl}/assets/${img.id}?key=16-9`}/></SwiperSlide>;
                })}
              </Swiper>
              :
              <Animation frames={expo.animation ? expo.animation.frames : []}/>
            }
          </div>
        </Grid>
      <Grid item xs={12} md={4}>
      </Grid>
    </Grid>
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="exposition-text" dangerouslySetInnerHTML={{__html:expo ? (expo.text ? translate(expo,'text') : (expo.resume ? translate(expo,'resume'): '')) : ''}}/>
        {expo.artistes.map((artiste)=><Biographie key={artiste.artistes_id.id} artiste={artiste.artistes_id}/>)}
        <Ressources item={expo}/>
      </Grid>
      <Grid item xs={12} md={4}>
        {expo.rdvs.map((rdv,i)=><RdvTile key={i} rdv={rdv.rdvs_id} size="mini"/>)}
      </Grid>
    </Grid>
  </div> : '';
}
export default Exposition;
