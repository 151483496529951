import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ResidencePassee({residence}) {
  const dataResidence=[
    {key:'residence-passee',value:residence.title},
    Utils.renderArtistes(residence,false,false),
    {key:null,value:Utils.dates(residence)},
  ];
  return <div className="residence-bloc">
    <SlugLink item={residence}><CartoucheInfos data={dataResidence}/></SlugLink>
  </div>;
}
export default ResidencePassee;
