import { gql } from '@apollo/client';
const queries={
  expositions: gql`
  query Expositions {
    expositions(filter:{status:{_eq: "Published" }}) {
      id
      title
      resume
      date_from
      date_to
      artistes {
        artistes_id {
          id
          name
        }
      }
      animation {
        frames {
          id
          sort
          directus_files_id {
            id
          }
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
  }
  `,
  residences: gql`
  query Residences {
    residences(filter:{status:{_eq: "Published" }}) {
      id
      title
      resume
      date_from
      date_to
      artistes {
        artistes_id {
          id
          name
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      image {
        id
      }
    }
  }
  `,
  editions: gql`
  query Editions {
    editions(filter:{status:{_eq: "Published" }}) {
      id
      title
      artistes {
        artistes_id {
          id
          name
        }
      }
      type {
        nom
        translations {
          languages_code {
            code
            name
          }
          nom
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      visuel {
        id
      }
    }
  }
  `,
  ac: gql`
  query Ac {
    ac {
      id
      title
      text
      text2
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_project(filter:{status:{_eq: "Published" }}) {
      id
      title
      title_liste
      text
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_instances(filter:{status:{_eq: "Published" }}) {
      id
      title
      text
      visuel {
        id
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      ac_projects {
        ac_project_id {
          id
          title
          text
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
      }
    }
    rdvs (filter: {_and:[
      {status : { _eq: "Published"}},
      {_or:[
        {agenda:{ _eq: "eac"}},
        {agenda:{ _eq: "both"}}
      ]}
    ]}) {
      id
      type {
        name
        translations {
          languages_code {
            code
            name
          }
          name
        }
      }
      title
      date
      time_from
      time_to
      lieu
      ville
      visuel{
        id
      }
      resume
      repetitions {
        titre
        date
        time_from
        time_to
      }
      slugs {
          slug
          date_created
      }
    }
  }
  `,
  ac_project: gql`
  query AcProject($id: Float!) {
    ac {
      id
      title
      text
      text2
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    propositions: ac_project(filter:{status:{_eq: "Published" }}) {
      id
      title
      translations {
        languages_code {
          code
          name
        }
        title
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
    ac_project(filter: { id : { _eq: $id}}) {
      id
      title
      text
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      ac_instances {
        ac_instances_id {
          id
          title
          text
          visuel {
            id
          }
          slugs(sort: ["-date_created"], limit: 1) {
            slug
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
    }
  }
  `,
  ac_instances: gql`
  query AcInstances {
    ac_instances(filter:{status:{_eq: "Published" }}) {
      id
      title
      text
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      ac_projects {
        id
        title
        text
        translations {
          languages_code {
            code
            name
          }
          title
          text
        }
      }
    }
  }
  `,
  ac_instance: gql`
  query AcInstance($id: Float!) {
    ac_instances(filter: { id : { _eq: $id}}) {
      id
      title
      text
      visuel {
        id
      }
      slugs(sort: ["-date_created"], limit: 1) {
        slug
      }
      translations {
        languages_code {
          code
          name
        }
        title
        text
      }
      ac_projects {
        ac_project_id {
          id
          title
          text
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
      }
      rdvs {
        rdvs_id(filter: { status : { _eq: "Published"}}){
          id
          type {
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
          }
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
      }
    }
  }
  `,
  exposition:gql`
    query Exposition($id: Float!){
        expositions(filter: { id : { _eq: $id}}) {
          id
          title
          resume
          text
          documents {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          artistes {
            artistes_id {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          animation {
            frames {
          	  id
              sort
              directus_files_id {
                id
              }
          	}
          }
          image {
            id
          }
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            resume
            text
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              type {
                name
                translations {
                  languages_code {
                    code
                    name
                  }
                  name
                }
              }
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
              }
              resume
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  residence:gql`
    query Residence($id: Float!){
        residences(filter: { id : { _eq: $id}}) {
          id
          title
          resume
          text
          ressources {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          partners {
            id
            partner_id {
              id
              name
            }
          }
          artistes {
            artistes_id {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          image {
            id
          }
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            resume
            text
          }
          rdvs{
            rdvs_id(filter: { status : { _eq: "Published"}}) {
              id
              type {
                name
                translations {
                  languages_code {
                    code
                    name
                  }
                  name
                }
              }
              title
              date
              time_from
              time_to
              lieu
              ville
              visuel{
                id
              }
              resume
              repetitions {
                titre
                date
                time_from
                time_to
              }
              slugs {
                  slug
                  date_created
              }
            }
          }
        }
    }
  `,
  edition:gql`
    query Edition($id: Float!){
        editions(filter: { id : { _eq: $id}}) {
          id
          title
          text
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          partners {
            id
            partner_id {
              id
              name
            }
          }
          artistes {
            artistes_id {
              id
              name
              bio
              translations {
                languages_code {
                  code
                  name
                }
                bio
              }
            }
          }
          visuel {
            id
          }
          slugs {
              slug
              date_created
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
    }
  `,
  langswitch:gql`
    query LangSwitch{
        languages {
          code
        }
    }
  `,
  page:gql`
    query Page($id: Float!){
        pages(filter: { id : { _eq: $id}, status : { _eq: "Published"}}) {
          id
          title
          text
          rubrique {
            id
            title
            translations {
              languages_code {
                code
                name
              }
              title
            }
          }
          visuel{
            id
          }
          slugs {
              slug
              date_created
          }
          diaporama {
            id
            directus_files_id {
              id
              title
              filesize
              type
            }
          }
          translations {
            languages_code {
              code
              name
            }
            title
            text
          }
        }
    }
  `,
  accueil:gql`
    query RdvTile{
        rdvs(filter: { status : { _eq: "Published"}}) {
          id
          type {
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
          }
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
        expositions(filter:{ status : { _eq: "Published"}}) {
          id
          title
          resume
          date_from
          date_to
          artistes {
            artistes_id {
              id
              name
            }
          }
          animation {
          	frames {
          	  id
              sort
              directus_files_id {
                id
              }
          	}
          }
          slugs(sort: ["-date_created"], limit: 1) {
            slug
          }
        }
    }
  `,
  rdvs:gql`
    query Rdvs{
        types(sort: ["name"]) {
          name
        }
        rdvs(filter: { status : { _eq: "Published"}}) {
          id
          type {
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
          }
          resume
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  rdv:gql`
    query Rdv($id: Float!){
        rdvs(filter: { id: { _eq: $id }, status : { _eq: "Published"}}) {
          id
          type {
            name
            translations {
              languages_code {
                code
                name
              }
              name
            }
          }
          title
          date
          time_from
          time_to
          lieu
          ville
          visuel{
            id
          }
          resume
          text
          repetitions {
            titre
            date
            time_from
            time_to
          }
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  allroutes:gql`
    query AllRoutes {
        expositions(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        pages(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        rdvs(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        residences(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        editions(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        ac_project(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
        ac_instances(filter: { status : { _eq: "Published"}}) {
          id
          slugs {
              slug
              date_created
          }
        }
    }
  `,
  rubriques:gql`
    query Rubriques {
        rubriques(filter: { status : { _eq: "Published"}}) {
          id
          title
          slugs {
              slug
          }
          translations {
              languages_code {
                code
                name
              }
              title
          }
          pages {
            id
            title
            translations {
              languages_code {
                code
                name
              }
              title
            }
            slugs {
              slug
            }
          }
        }
        pages(filter: {rubrique: {id:{_null:true}}}) {
          id
          title
          translations {
            languages_code {
              code
              name
            }
            title
          }
          slugs {
            slug
          }
        }
    }
  `,
  settings:gql`
    query Settings{
        settings {
          contrast_color
          sitename
          websitename
          plateforme_baseline
          plateforme_desc_1
          plateforme_desc_2
          plateforme_desc_3
          translations {
            languages_code {
              code
              name
            }
            plateforme_baseline
            plateforme_desc_1
            plateforme_desc_2
            plateforme_desc_3
          }
        }
        interface {
          key
          value
          text
          translations {
            languages_code {
              code
              name
            }
            value
            text
          }
        }
    }
  `,
}
export default queries;
