import React, {useContext} from "react";
import Utils from 'utils/Utils';
import './RdvTile.scss'
import Conf from 'utils/Conf';
import { parseDates,displayDates,parseTime } from 'components/Rdv/Rdv';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';

function RdvTile({rdv,size='big'}) {
  const { t, lang, translate } = useContext(SettingsContext);
  return rdv ? <SlugLink item={rdv}>
  { size==='big' && <div className="rdv-tile">
      <div className="rdv-tile-type">{translate(rdv.type,'name')}</div>
      <div className="rdv-tile-titre">{rdv.title}</div>
      <div className="rdv-tile-visuel">
          <img key={rdv.visuel.id} alt="" src={`${Conf.apiUrl}/assets/${rdv.visuel.id}?key=carre-small`}/>
      </div>
      <div className="rdv-tile-dates">
          {Utils.dates(rdv)} – {parseTime(rdv,true)}
      </div>
      <div className="rdv-tile-lieu">{rdv.lieu}</div>
      <div className="rdv-tile-ville">{rdv.ville}</div>
      <div className="rdv-tile-resume" dangerouslySetInnerHTML={{__html:rdv.resume}}></div>
  </div>}
  { size==='mini' && <div className="rdv-tile">
      <div className="rdv-tile-type">{translate(rdv.type,'name')}</div>
      <div className="rdv-tile-mini-titre">{rdv.title}</div>
      <div className="rdv-tile-mini-dates">
          {displayDates(parseDates(rdv,lang),t)}
      </div>
      {(rdv.lieu || rdv.ville) && <div className="rdv-tile-mini-lieu">{rdv.lieu}{rdv.lieu && rdv.ville && ', '}{rdv.ville}</div>}
      <div className="rdv-tile-mini-resume" dangerouslySetInnerHTML={{__html:rdv.resume}}></div>
  </div>}
  </SlugLink> : '';
}
export default RdvTile;
