import React from "react";
import Utils from 'utils/Utils';
import './Residence.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ResidenceEnCours({residence}) {
  const dataResidence=[
    {key:'residence-en-cours',value:residence.title},
    Utils.renderArtistes(residence,false,false),
    {key:null,value:Utils.dates(residence)},
    {image:residence.image},
  ];
  console.log(dataResidence);
  return <div className="residence-bloc">
    <SlugLink item={residence}><CartoucheInfos data={dataResidence}/></SlugLink>
  </div>;
}
export default ResidenceEnCours;
