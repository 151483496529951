import React from "react";
import Tag from 'components/Tag';
import Artiste from 'components/Artiste';
import Expo from 'components/Expo';
import { DateTime } from "luxon";

const Utils={
    shuffle: (tab)=> {
        let array=[...tab];
        for(let i = array.length - 1; i > 0; i--){
        const j = Math.floor(Math.random() * i)
        const temp = array[i]
        array[i] = array[j]
        array[j] = temp
      }
      return array;
    },
    translate: (item,lang,key) => {
        let res=item ? item[key] : '';
        if (item.translations) {
            const tr=item.translations.find((o)=>o.languages_code && o.languages_code.code===lang);
            if (tr && tr[key]) res=tr[key];
        }
        return res;
    },
    accentsTidy:function(s) {
        var map = [
            ["[àáâãäå]", "a"],
            ["æ", "ae"],
            ["ç", "c"],
            ["[èéêë]", "e"],
            ["[ìíîï]", "i"],
            ["ñ", "n"],
            ["[òóôõö]", "o"],
            ["œ", "oe"],
            ["[ùúûü]", "u"],
            ["[ýÿ]", "y"],
        ];
        for (var i=0; i<map.length; ++i) {
            s = s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
                return (match) => {
                    if (match.toUpperCase() === match) {
                        return map[j][1].toUpperCase();
                    } else {
                        return map[j][1];
                    }
                }
            })(i));
        }
        return s;
    },
    accentsTidyLw:(s)=>{
        return Utils.accentsTidy(s).toLowerCase();
    },
    type2icon:(t)=>{
        if (t==='images') return 'image';
        if (t==='videos') return 'video';
        if (t==='multimedias') return 'multimedia';
        if (t==='textes') return 'texte';
        if (t==='audios') return 'audio';
        if (t==='groupes') return 'groupe';
    },
    //Cartouches
    renderM2M:(item,key)=>item[key] && item[key].length>0 ? item[key].map((o)=>o[key+'_id']).filter((o)=>Boolean(o)) : [],
    renderExpos:(item)=>{
        const expo=Utils.renderM2M(item,'expositions');
        return [{value:expo.length>0 ? expo.map((e,i)=>e ? <React.Fragment key={e.id}>{i>0 && ', '}<Expo expo={e} toggle={false}/></React.Fragment>: '') : ''}];
    },
    renderArtistes:(item,active=false,key=true,size='big')=>{
        const artistes=Utils.renderM2M(item,'artistes');
        return {
          key:key ? (artistes.length>1 ? 'artistes' : 'artiste') : null ,
          value:artistes.length>0 ? artistes.map((artiste,i)=><React.Fragment key={artiste.id}>{i>0 && ', '}<Artiste artiste={artiste} active={active}/></React.Fragment>) : '',
          size
        };
    },
    renderTags:(item)=>{
        const tags=Utils.renderM2M(item,'tags');
        return {key:'tags',value:tags.length>0 ? tags.map((tag,i)=><React.Fragment key={tag.id}>{i>0 && ', '}<Tag tag={tag}/></React.Fragment>) : ''};
    },
    dates:(item)=>{
        if (item.date_from && item.date_to) return Utils.date(item.date_from)+'-'+Utils.date(item.date_to);
        if (item.date_from) return Utils.date(item.date_from);
        if (item.date) return Utils.date(item.date);
        return '';
    },
    date:(d)=>{
        return DateTime.fromISO(d).toFormat('dd|LL|yy');
    },
    pathPageMatch:(pathname,page)=>{
        let test=false;
        if (pathname===`/pages/${page.id}`) test=true;
        page.slugs.forEach((s)=>{
            if (pathname===`/pages/${s.slug}`) test=true;
        });
        return test;
    }
}
export default Utils;
