import React, {useContext} from "react";
import { useQuery } from '@apollo/client';
import './Ac.scss'
import Queries from 'utils/Queries';
import Grid from '@material-ui/core/Grid';
import CartoucheInfos from 'components/CartoucheInfos';
import AcInstanceTile from './AcInstanceTile';
import { SettingsContext } from 'Providers/SettingsProvider';
import SlugLink from 'components/Router/SlugLink';

function AcProject({id}) {
  const { translate,t } = useContext(SettingsContext);
  const { data } = useQuery(Queries.ac_project,{
    variables: { id:parseFloat(id) },
  });
  const ac=data ? data.ac : null;
  const ac_project=data ? data.ac_project[0] : null;
  const propositions=data ? data.propositions : null;
  const acInstances=ac_project ? ac_project.ac_instances : null;
  const dataAc=ac_project ? [
    {key:null,value:translate(ac_project,'title')},
  ]: [];
  return ac_project ? <div className="ac-project">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        <div className="ac-type">{translate(ac,'title')}</div>
        <CartoucheInfos data={dataAc}/>
        <div className="ac-text" dangerouslySetInnerHTML={{__html:ac_project ? translate(ac_project,'text') : ''}}/>
        <hr />
        {acInstances && <Grid container spacing={2}>
          {acInstances.map((acInstance)=><Grid key={acInstance.ac_instances_id.id} item xs={12} md={6}>
            <AcInstanceTile acInstance={acInstance.ac_instances_id}/>
          </Grid>)}
        </Grid>}
      </Grid>
      {propositions && <Grid item xs={12} md={4}>
        <div className="ac-type">{t('actions')}</div>
        {propositions.map((proposition)=><div className={proposition.id===id ? 'menu-ac active' : 'menu-ac'} key={proposition.id}>
          <SlugLink item={proposition}>{translate(proposition,'title')}</SlugLink>
        </div>)}
      </Grid>}
    </Grid>
  </div> : '';
}
export default AcProject;
