import React, {useEffect,useState} from "react";
import './Animation.scss';
import Conf from 'utils/Conf';

function Animation({frames}) {
  const [ counter,setCounter ] = useState(0);
  useEffect(()=>{
    let intervalId= setInterval(()=>{
        setCounter((c)=>c+1);
    }, 1000);
    return ()=>clearInterval(intervalId);
  },[setCounter]);
  const step=counter%(frames.length+5);
  return <div className={'animation step-'+step}>
    {[...frames].sort((a,b)=>a.sort-b.sort).map((frame,i)=>{
      const visible=i<=step;
      return <img className={visible ? 'visible' : ''} key={frame.directus_files_id.id} alt="" src={`${Conf.apiUrl}/assets/${frame.directus_files_id.id}`}/>
    })}
  </div>;
}

export default Animation;
