import React, {useContext,useEffect} from "react";
import { useQuery } from '@apollo/client';
import './Page.scss'
import Queries from 'utils/Queries';
import CartoucheInfos from 'components/CartoucheInfos';
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';
import Grid from '@material-ui/core/Grid';

function Page({id}) {
  const { translate,setCurrentRub } = useContext(SettingsContext);
  const { data } = useQuery(Queries.page,{
    variables: { id:parseFloat(id) },
  });
  const page=data ? data.pages[0] : null;
  const dataPage=page ? [
    {key_tr:page.rubrique ? translate(page.rubrique,'title') : translate(page,'title'),value:translate(page,'title')},
  ]: [];
  useEffect(()=>{
    if (page && page.rubrique) {
      setCurrentRub(page.rubrique.id);
    } else {
      setCurrentRub(null);
    }
    return ()=>setCurrentRub(null);
  },[page,setCurrentRub]);
  return page ? <div className='page'>
  <Grid container spacing={2}>
    <Grid item xs={12} md={8}>
      <CartoucheInfos data={dataPage}/>
      {page.visuel && <div className="page-visuel">
          <img key={page.visuel.id} alt="" src={`${Conf.apiUrl}/assets/${page.visuel.id}?key=16-9`}/>
      </div>}
      <div className="page-text" dangerouslySetInnerHTML={{__html:page ? page.text : ''}}/>
    </Grid>
  </Grid>
  </div> : '';
}
export default Page;
