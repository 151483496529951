import React from "react";
import './Accueil.scss'
import ExpoEnCours from 'components/Exposition/ExpoEnCours';
import RdvTile from 'components/Rdv/RdvTile';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";

function Accueil() {
  const { data } = useQuery(Queries.accueil);
  let rdvs= data ? data.rdvs : [];
  let expos= data ? data.expositions.filter((o)=>DateTime.fromISO(o.date_to)>DateTime.now()) : [];
  return (<div className="accueil">
  {expos.map((expo,i)=><ExpoEnCours key={i} expo={expo} home={true}/>)}
  <Grid container spacing={2}>
      {rdvs.map((rdv,i)=><Grid key={i} item xs={12} sm={6} md={4}>
          <RdvTile rdv={rdv}/>
      </Grid>)}
  </Grid>
  </div>);
}
export default Accueil;
