import React from "react";
import './Editions.scss'
import EditionBloc from 'components/Edition/EditionBloc';
import Grid from '@material-ui/core/Grid';
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';

function Editions() {
  const { data } = useQuery(Queries.editions);
  let editions= data ? data.editions : [];
  return (<div className="editions">
    <Grid container spacing={2}>
      <Grid item xs={12} md={8}>
        {editions.map((edition,i)=><EditionBloc key={edition.id} edition={edition}/>)}
      </Grid>
    </Grid>
  </div>);
}
export default Editions;
