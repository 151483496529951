import React, { useContext } from "react";
import './Rdv.scss'
import RdvTile from 'components/Rdv/RdvTile';
import Grid from '@material-ui/core/Grid';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { DateTime } from "luxon";
import { SettingsContext } from 'Providers/SettingsProvider';
import 'components/CartoucheInfos/CartoucheInfos.scss';
import {ReactComponent as Carre} from 'imgs/Carre.svg';

const parseDate=(rdv)=>DateTime.fromISO(rdv.date);

function Rdvs() {
  const { t, filter, setFilter } = useContext(SettingsContext);
  const { data } = useQuery(Queries.rdvs);
  let rdvs= data ? data.rdvs : [];
  let types= data ? data.types : [];
  let computedRdv=[];
  rdvs.forEach((rdv, i) => {
      const {date, time_from, time_to, repetitions, ...otherProps} = rdv;
      let currentRdv={...otherProps};
      if (
        (filter.archives && DateTime.fromISO(date)<DateTime.now())
        || (!filter.archives && DateTime.fromISO(date)>=DateTime.now())
      ) computedRdv.push({...currentRdv, date, time_from, time_to});
      repetitions.forEach((r, i) => {
        if (
          (filter.archives && DateTime.fromISO(r.date)<DateTime.now())
          || (!filter.archives && DateTime.fromISO(r.date)>=DateTime.now())
        ) computedRdv.push({...currentRdv, date:r.date, time_from:r.time_from, time_to:r.time_to});
      });
  });
  computedRdv.sort((a,b)=>{
    const dateA=parseDate(a);
    const dateB=parseDate(b);
    if (dateA < dateB )
       return filter.archives ? 1 : -1;
    if (dateA > dateB )
       return filter.archives ? -1 : 1;
    return 0;
  });
  const handleTypeChange=(name)=>{
    const {types:filterTypes,...otherFilters}=filter;
    const newTypes=[...filterTypes];
    const idx=newTypes.indexOf(name);
    if (idx!==-1) newTypes.splice(idx,1);
    else newTypes.push(name);
    setFilter({types:newTypes,...otherFilters});
  }
  const handleArchivesChange=()=>{
    const {archives,...otherFilters}=filter;
    setFilter({archives:!archives,...otherFilters});
  }
  let classesArchives='filter-type archives';
  if (filter.archives) classesArchives+=" active";
  return (<div className="rdvs">
  <Grid container spacing={2}>
    <Grid item xs={12} sm={8}>
      <Grid container spacing={2}>
          {computedRdv.filter((r)=>filter.types.length===0 || filter.types.indexOf(r.type.name)!==-1).map((rdv,i)=><Grid key={i} item xs={12} sm={6}>
              <RdvTile rdv={rdv}/>
          </Grid>)}
      </Grid>
    </Grid>
    <Grid item xs={12} sm={4} className="cartouche-infos">
      <div className="cartouche-infos-label">{t('filtres')}</div>
      {types.map((ty,i)=>{
        let classes='filter-type';
        if (filter.types.indexOf(ty.name)!==-1) classes+=" active";
        return <div key={i} className={classes} onClick={()=>handleTypeChange(ty.name)}><Carre/> {ty.name}</div>
      })}
      <div key={'archives'} className={classesArchives} onClick={()=>handleArchivesChange()}><Carre/> {t('archives')}</div>
    </Grid>
  </Grid>
  </div>);
}
export default Rdvs;
