import React from "react";
import './Footer.scss';
import FooterClone from 'components/Footer/FooterClone';

function Footer() {
  return <><div className="footer" id="footer">
      <div className="footer-inner">
          <div className="footer-baseline">Footer</div>

      </div>
  </div>
  <FooterClone/>
  </>;
}

export default Footer;
