import React from "react";
import {
  Link
} from "react-router-dom";

const SlugLink = React.forwardRef(({ item, className, children, forwardedRef }, ref) => {
  const slug=item ? ( item.slugs && item.slugs[0] ? item.slugs[0].slug : item.id ) : '';
  const type=item ? item.__typename : '';
  const link='/'+type+'/'+slug;
  return <Link to={link} className={className} ref={ref || forwardedRef}>{children}</Link>;
});

export default SlugLink;
