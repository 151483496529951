import React from "react";
import Utils from 'utils/Utils';
import './Exposition.scss'
import CartoucheInfos from 'components/CartoucheInfos';
import SlugLink from 'components/Router/SlugLink';

function ExpoPassee({expo}) {
  const dataExpo=[
    {key:'exposition-passee',value:expo.title},
    Utils.renderArtistes(expo,false,false),
    {key:null,value:Utils.dates(expo),size:'small'},
  ];
  return <div className="expo-bloc">
    <SlugLink item={expo}><CartoucheInfos data={dataExpo}/></SlugLink>
  </div>;
}
export default ExpoPassee;
