import React, {useContext} from "react";
import './CartoucheInfos.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import Animation from 'components/Animation';
import Conf from 'utils/Conf';

function CartoucheInfos({data}) {
  const { t } = useContext(SettingsContext);
  const renderItem=(item,i)=><React.Fragment key={i}>
      {item.value!=='' && item.key && <div className="cartouche-infos-label">{t(item.key)}</div>}
      {item.value!=='' && item.value && item.key_tr && <div className="cartouche-infos-label">{item.key_tr}</div>}
      {item.value!=='' && item.value && <div className={"cartouche-infos-value size-"+(item.size ? item.size : 'big')}>{item.value}</div>}
      {item.animation!=='' && item.animation && <div className="cartouche-infos-animation"><Animation frames={item.animation.frames}/></div>}
      {item.image && <div className="cartouche-infos-image"><img alt='' src={`${Conf.apiUrl}/assets/${item.image.id}?key=16-9-crop`}/></div>}
      {item.text!=='' && item.text && <div className="cartouche-infos-texte" dangerouslySetInnerHTML={{__html:item.text}}></div>}
  </React.Fragment>;
  let classes="cartouche-infos";
  return data ? <div className={classes}>
      {data.map((item,i)=>{
          return renderItem(item,i);
      })}

  </div> : '';
}

export default CartoucheInfos;
